import React, { useState } from "react";
import copy from "copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import { Button, Form, InputGroup } from "react-bootstrap";

function ShareReportButton() {
  const [show, setShow] = useState(false);
  const [copyURL, setCopyURL] = useState(
    window.location.href.substring(-1, window.location.href.lastIndexOf("/")).replace("/", "") + window.location.search + "#capacity-build-report"
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCopyText = (e) => {
    setCopyURL(e.target.value);
  };

  const copyToClipboard = () => {
    copy(copyURL);
    // alert(`You have copied "${copyURL}"`);
  };

  // Function to update copyURL when the button is clicked
  const handleReset = () => {
    const updatedCopyURL = window.location.href.substring(-1, window.location.href.lastIndexOf("/")).replace("/", "") + window.location.search + "#capacity-build-report";
    setCopyURL(updatedCopyURL);
    handleShow();
  };

  return (
    <>
      <p className="text-center">
        <Button type="button" variant="primary" className="btn" onClick={handleReset}>
          Share
        </Button>
      </p>

      <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Share this report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <Form.Control placeholder="Share URL" aria-label="Share URL" value={copyURL} aria-describedby="share-btn" onChange={handleCopyText} />
            <Button variant="outline-primary" id="share-btn" onClick={copyToClipboard}>
              Copy to Clipboard
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareReportButton;
