import React from "react";
// import getTotal from "../../utils/Utilities.js";
// import FilterDropdownList from "../dropdowns/FilterDropdownList.js";
// import MultiSelectDropdown from "../dropdowns/MultiselectDropdown.js";

function CountriesList({ countriesArray }) {
  let countriesList = <li>Loading...</li>;

  let countriesToShowInList = ["Bangladesh", "Ethiopia", "Ghana", "Uganda", "Zambia", "Nigeria", "South Africa", "Zimbabwe"];

  if (countriesArray.length > 0) {
    // To prevent duplicates in the countries list
    const mappedCountries = [];

    countriesList = countriesArray.map((country) => {
      if (countriesArray.length === 152) {
        if (countriesToShowInList.includes(country.name) && !mappedCountries.includes(country.name)) {
          // This makes sure we don't have duplicates -may not be needed anymore but leaving it for now
          mappedCountries.push(country.name);
          return (
            <li key={country.id}>
              <div className="index-color" style={{ backgroundColor: country.countryColor }}></div>
              {country.name}
            </li>
          );
        } else {
          return null;
        }
      } else {
        return (
          <li key={country.id}>
            <div className="index-color" style={{ backgroundColor: country.countryColor }}></div>
            {country.name}
          </li>
        );
      }
    });
  }

  /**
   * Sets the color of the country based on the total number of participants and graduates
   * @param {number} totalParticipants - The total number of participants
   * @param {number} totalGraduates - The total number of graduates
   * @returns {string} countryColor - The color of the country
   */
  // function setColorOnCount(totalParticipants, totalGraduates, overallTotal) {
  //   let countryColor = "#ED4E4E"; // defaults to less than 100 color key
  //   let total = 0;
  //   if (totalParticipants) {
  //     total += getTotal(totalParticipants);
  //   }
  //   if (totalGraduates) {
  //     total += getTotal(totalGraduates);
  //   }
  //   if (overallTotal) {
  //     total = overallTotal;
  //   }

  //   // Set the country color based on the total
  //   if (total > 99 && total < 199) {
  //     countryColor = "#E8112D";
  //   } else if (total > 199 && total < 299) {
  //     countryColor = "#C20E25";
  //   } else if (total > 299 && total < 399) {
  //     countryColor = "#90102A";
  //   } else if (total > 399) {
  //     countryColor = "#63102A";
  //   }

  //   return countryColor;
  // }

  return (
    <div className="list-container mt-3">
      <ul className="list">{countriesList}</ul>
    </div>
  );
}

export default CountriesList;
