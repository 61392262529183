import React from "react";
import "./ReportSummary.scss";

function ReportSummary(props) {
  // console.log(props.countriesArray);

  let newGrandTotal = 0;

  let countMatches = 0;
  let summaryCountries = [];
  props.countriesArray.forEach((country) => {
    if (props.filterSelect.current) {
      if (props.filterSelect !== 0) {
        if (props.filterSelect.current.includes(country.name)) {
          countMatches++;
          // getIndividualTotals(country);
          summaryCountries.push(country);
          newGrandTotal += country.overallTotal;
        } else {
          countMatches++;
          summaryCountries.push(country);
          newGrandTotal += country.overallTotal;
        }
      }
    } else {
      newGrandTotal += country.overallTotal;
    }
  });

  // console.log(countMatches);

  const sortedSummaryCountries = summaryCountries.sort((a, b) => b.overallTotal - a.overallTotal);

  // console.log(sortedSummaryCountries);

  const programTotals = sortedSummaryCountries.reduce((acc, country) => {
    country.sectors.forEach((sector) => {
      sector.programs.forEach((program) => {
        if (!acc[program.program]) {
          acc[program.program] = {
            participants: { Male: 0, Female: 0, Unknown: 0 },
            graduates: { Male: 0, Female: 0, Unknown: 0 },
            total: { participants: 0, graduates: 0 },
            grandTotal: 0,
          };
        }
        acc[program.program].participants.Male += program.total.participants.Male;
        acc[program.program].participants.Female += program.total.participants.Female;
        acc[program.program].participants.Unknown += program.total.participants.Unknown;
        acc[program.program].graduates.Male += program.total.graduates.Male;
        acc[program.program].graduates.Female += program.total.graduates.Female;
        acc[program.program].graduates.Unknown += program.total.graduates.Unknown;

        acc[program.program].total.participants += program.total.participants.Male + program.total.participants.Female + program.total.participants.Unknown;
        acc[program.program].total.graduates += program.total.graduates.Male + program.total.graduates.Female + program.total.graduates.Unknown;
        acc[program.program].grandTotal = acc[program.program].total.participants + acc[program.program].total.graduates;
      });
    });
    return acc;
  }, {});

  const sectorTotals = sortedSummaryCountries.reduce((acc, country) => {
    country.sectors.forEach((sector) => {
      if (!acc[sector.sector]) {
        acc[sector.sector] = {
          participants: { Male: 0, Female: 0, Unknown: 0 },
          graduates: { Male: 0, Female: 0, Unknown: 0 },
          total: { participants: 0, graduates: 0 },
          grandTotal: 0,
        };
      }
      sector.programs.forEach((program) => {
        acc[sector.sector].participants.Male += program.total.participants.Male;
        acc[sector.sector].participants.Female += program.total.participants.Female;
        acc[sector.sector].participants.Unknown += program.total.participants.Unknown;
        acc[sector.sector].graduates.Male += program.total.graduates.Male;
        acc[sector.sector].graduates.Female += program.total.graduates.Female;
        acc[sector.sector].graduates.Unknown += program.total.graduates.Unknown;

        acc[sector.sector].total.participants += program.total.participants.Male + program.total.participants.Female + program.total.participants.Unknown;
        acc[sector.sector].total.graduates += program.total.graduates.Male + program.total.graduates.Female + program.total.graduates.Unknown;
        acc[sector.sector].grandTotal = acc[sector.sector].total.participants + acc[sector.sector].total.graduates;
      });
    });
    return acc;
  }, {});

  const genderTotals = sortedSummaryCountries.reduce((acc, country) => {
    country.sectors.forEach((sector) => {
      sector.programs.forEach((program) => {
        Object.entries(program.total.participants).forEach(([gender, count]) => {
          if (!acc[gender]) {
            acc[gender] = {
              participants: 0,
              graduates: 0,
              grandTotal: 0,
            };
          }
          acc[gender].participants += count;
          acc[gender].grandTotal += count;
        });
        Object.entries(program.total.graduates).forEach(([gender, count]) => {
          if (!acc[gender]) {
            acc[gender] = {
              participants: 0,
              graduates: 0,
              grandTotal: 0,
            };
          }
          acc[gender].graduates += count;
          acc[gender].grandTotal += count;
        });
      });
    });
    return acc;
  }, {});

  let countryA = "";
  let countryATotal = 0;
  let countryB = "";
  let countryBTotal = 0;
  let countryC = "";
  let countryCTotal = 0;
  let remainingTotal = 0;
  let remainingPecentage = 0;
  // let invalidSelections = ((sortedSummaryCountries[0].overallTotal / newGrandTotal) * 100).toFixed(0);
  // console.log(invalidSelections);

  // create a new array with all unique programme names, without "Unknown"
  const uniqueProgrammes = Array.from(
    new Set(
      props.countriesArray.flatMap((country) =>
        country.sectors.flatMap((sector) => sector.programs.filter((program) => program.program !== "Unknown").map((program) => program.program))
      )
    )
  );
  // create a new array with all unique sector names, without "Unknown"
  const uniqueSectors = Array.from(
    new Set(props.countriesArray.flatMap((country) => country.sectors.filter((sector) => sector.sector !== "Unknown").map((sector) => sector.sector)))
  );
  // define a function to create comma-separated lists with "and" before the last item
  function createList(items) {
    let list = "";
    if (items.length === 1) {
      list = items[0];
    } else if (items.length > 1) {
      list = items.slice(0, -1).join(", ") + " and " + items.slice(-1);
    }
    return list;
  }
  const programmesList = createList(uniqueProgrammes);
  const sectorsList = createList(uniqueSectors);

  let filterSummary = (
    <>
      <p>
        <b>Capacity report overview</b>
      </p>
      {/* <p>
        Digital Frontiers has developed capacity and built lasting relationships in {props.countriesArray.length - 1} countries to date. Our work encompasses programmes that
        develop skills in {programmesList}, and equip people working in the {sectorsList} sectors to advance their careers and achieve their goals.
      </p> */}
      <p>
        Digital Frontiers has developed capacity and built lasting relationships in 152 countries to date. Our work encompasses programmes that develop skills in Digital Financial
        Services, Financial Regulation and Supervision, Instant and Inclusive Payment Systems, Gender Equality, Inclusive Digital Economy and Digital Payments in Healthcare and
        equip people working in the Development, Private and Public sectors to advance their careers and achieve their goals while also contributing to the achievement of the UN
        Sustainable Development Goals.
      </p>
    </>
  );
  if (sortedSummaryCountries.length === 1) {
    countryA = sortedSummaryCountries[0].name;
    countryATotal = sortedSummaryCountries[0].overallTotal;
    filterSummary = (
      <>
        <p className="country-representation">Country representation</p>
        <p>
          {countryA} had {countryATotal} ({newGrandTotal === 0 ? 0 : ((countryATotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current}.
        </p>
      </>
    );
  } else if (sortedSummaryCountries.length === 2) {
    countryA = sortedSummaryCountries[0].name;
    countryATotal = sortedSummaryCountries[0].overallTotal;
    countryB = sortedSummaryCountries[1].name;
    countryBTotal = sortedSummaryCountries[1].overallTotal;

    filterSummary = (
      <>
        <p className="country-representation">Country representation</p>
        <p>
          In your selection of countries, {countryA} had {countryATotal} ({newGrandTotal === 0 ? 0 : ((countryATotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current}, compared to {countryBTotal} ({newGrandTotal === 0 ? 0 : ((countryBTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for {countryB}.
        </p>
      </>
    );
  } else if (sortedSummaryCountries.length > 2) {
    countryA = sortedSummaryCountries[0].name;
    countryATotal = sortedSummaryCountries[0].overallTotal;
    countryB = sortedSummaryCountries[1].name;
    countryBTotal = sortedSummaryCountries[1].overallTotal;
    countryC = sortedSummaryCountries[2].name;
    countryCTotal = sortedSummaryCountries[2].overallTotal;
    remainingTotal = newGrandTotal - countryATotal - countryBTotal - countryCTotal;
    remainingPecentage = ((remainingTotal / newGrandTotal) * 100).toFixed(2);

    filterSummary = (
      <>
        <p className="country-representation">Country representation</p>
        <p>
          In your selection of countries, {countryA} had {countryATotal} ({newGrandTotal === 0 ? 0 : ((countryATotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current}, compared to {countryBTotal} ({newGrandTotal === 0 ? 0 : ((countryBTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for {countryB} and {countryCTotal} ({newGrandTotal === 0 ? 0 : ((countryCTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for {countryC}.
          {countMatches > 3 ? " The remaining countries in your selection made up " + remainingTotal + " (" + remainingPecentage + "%) " + props.radioSelect.current + "." : ""}
        </p>
      </>
    );
  }

  const programTotalsArray = Object.keys(programTotals)
    .filter((programName) => programTotals[programName].grandTotal > 0)
    .map((programName) => ({
      Program: programName,
      ...programTotals[programName],
    }));

  const sortedProgramTotals = programTotalsArray.sort((a, b) => b.grandTotal - a.grandTotal);

  let filterSummaryPrograms = (
    <>
      {/*       <p className="program-representation">Program representation</p>
      <p>This will be a generic summary to show when not filtering</p> */}
    </>
  );
  let programA = "";
  let programATotal = 0;
  let programB = "";
  let programBTotal = 0;
  let programC = "";
  let programCTotal = 0;
  let programD = "";
  let programDTotal = 0;
  if (sortedProgramTotals.length === 1) {
    programA = sortedProgramTotals[0].Program;
    programATotal = sortedProgramTotals[0].grandTotal;
    filterSummaryPrograms = (
      <>
        <p className="program-representation">Program representation</p>
        <p>
          {programA} had {programATotal} ({((programATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current}.
        </p>
      </>
    );
  } else if (sortedProgramTotals.length === 2) {
    programA = sortedProgramTotals[0].Program;
    programATotal = sortedProgramTotals[0].grandTotal;
    programB = sortedProgramTotals[1].Program;
    programBTotal = sortedProgramTotals[1].grandTotal;
    filterSummaryPrograms = (
      <>
        <p className="program-representation">Programme representation</p>
        <p>
          In your selection of programmes, {programA} had {programATotal} ({((programATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current}, compared to{" "}
          {programBTotal} ({((programBTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programB}.
        </p>
      </>
    );
  } else if (sortedProgramTotals.length === 3) {
    programA = sortedProgramTotals[0].Program;
    programATotal = sortedProgramTotals[0].grandTotal;
    programB = sortedProgramTotals[1].Program;
    programBTotal = sortedProgramTotals[1].grandTotal;
    programC = sortedProgramTotals[2].Program;
    programCTotal = sortedProgramTotals[2].grandTotal;
    filterSummaryPrograms = (
      <>
        <p className="program-representation">Programme representation</p>
        <p>
          In your selection of programmes, {programA} had {programATotal} ({((programATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current}, compared to{" "}
          {programBTotal} ({((programBTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programB} and {programCTotal} (
          {((programCTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programC}.
        </p>
      </>
    );
  } else if (sortedProgramTotals.length === 4) {
    programA = sortedProgramTotals[0].Program;
    programATotal = sortedProgramTotals[0].grandTotal;
    programB = sortedProgramTotals[1].Program;
    programBTotal = sortedProgramTotals[1].grandTotal;
    programC = sortedProgramTotals[2].Program;
    programCTotal = sortedProgramTotals[2].grandTotal;
    programD = sortedProgramTotals[3].Program;
    programDTotal = sortedProgramTotals[3].grandTotal;
    filterSummaryPrograms = (
      <>
        <p className="program-representation">Programme representation</p>
        <p>
          In your selection of programmes, {programA} had {programATotal} ({((programATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current}, compared to{" "}
          {programBTotal} ({((programBTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programB}, {programCTotal} (
          {((programCTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programC} and {programDTotal} (
          {((programDTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for {programD}.
        </p>
      </>
    );
  }

  // console.log(sectorTotals);

  const sectorTotalsArray = Object.keys(sectorTotals)
    .filter((sectorName) => sectorTotals[sectorName].grandTotal > 0)
    .map((sectorName) => ({
      Sector: sectorName,
      ...sectorTotals[sectorName],
    }));

  const sortedSectorTotals = sectorTotalsArray.sort((a, b) => b.grandTotal - a.grandTotal);

  // console.log(sortedSectorTotals);

  let filterSummarySectors = (
    <>
      {/*       <p className="sector-representation">Sector representation</p>
      <p>This will be a generic summary to show when not filtering</p> */}
    </>
  );
  let sectorA = "";
  let sectorATotal = 0;
  let sectorB = "";
  let sectorBTotal = 0;
  let sectorC = "";
  let sectorCTotal = 0;
  let sectorD = "";
  let sectorDTotal = 0;
  if (sortedSectorTotals.length === 1) {
    sectorA = sortedSectorTotals[0].Sector;
    sectorATotal = sortedSectorTotals[0].grandTotal;
    filterSummarySectors = (
      <>
        <p className="sector-representation">Sector representation</p>
        <p>
          The {sectorA} sector had {sectorATotal} ({((sectorATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current}.
        </p>
      </>
    );
  } else if (sortedSectorTotals.length === 2) {
    sectorA = sortedSectorTotals[0].Sector;
    sectorATotal = sortedSectorTotals[0].grandTotal;
    sectorB = sortedSectorTotals[1].Sector;
    sectorBTotal = sortedSectorTotals[1].grandTotal;
    filterSummarySectors = (
      <>
        <p className="sector-representation">Sector representation</p>
        <p>
          In your selection, {sectorATotal} ({((sectorATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} come from the {sectorA} sector, compared to{" "}
          {sectorBTotal} ({((sectorBTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for the {sectorB} sector.
        </p>
      </>
    );
  } else if (sortedSectorTotals.length === 3) {
    sectorA = sortedSectorTotals[0].Sector;
    sectorATotal = sortedSectorTotals[0].grandTotal;
    sectorB = sortedSectorTotals[1].Sector;
    sectorBTotal = sortedSectorTotals[1].grandTotal;
    sectorC = sortedSectorTotals[2].Sector;
    sectorCTotal = sortedSectorTotals[2].grandTotal;
    filterSummarySectors = (
      <>
        <p className="sector-representation">Sector representation</p>
        <p>
          In your selection, {sectorATotal} ({((sectorATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} come from the {sectorA} sector, compared to{" "}
          {sectorBTotal} ({((sectorBTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for the {sectorB} sector, and {sectorCTotal} ({((sectorCTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for the{" "}
          {sectorC} sector.
        </p>
      </>
    );
  } else if (sortedSectorTotals.length === 4) {
    sectorA = sortedSectorTotals[0].Sector;
    sectorATotal = sortedSectorTotals[0].grandTotal;
    sectorB = sortedSectorTotals[1].Sector;
    sectorBTotal = sortedSectorTotals[1].grandTotal;
    sectorC = sortedSectorTotals[2].Sector;
    sectorCTotal = sortedSectorTotals[2].grandTotal;
    sectorD = sortedSectorTotals[3].Sector;
    sectorDTotal = sortedSectorTotals[3].grandTotal;
    filterSummarySectors = (
      <>
        <p className="sector-representation">Sector representation</p>
        <p>
          In your selection, {sectorATotal} ({((sectorATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} come from the {sectorA} sector, compared to{" "}
          {sectorBTotal} ({((sectorBTotal / newGrandTotal) * 100).toFixed(2)}
          %) {props.radioSelect.current} for the {sectorB} sector, {sectorCTotal} ({((sectorCTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for the{" "}
          {sectorC} sector and {sectorDTotal} ({((sectorDTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} for the {sectorD} sector.
        </p>
      </>
    );
  }

  // console.log(genderTotals);
  const genderTotalsArray = Object.keys(genderTotals)
    .filter((genderName) => genderTotals[genderName].grandTotal > 0)
    .map((genderName) => ({
      Gender: genderName === "Unknown" ? "Non-Binary and Undisclosed" : genderName,
      ...genderTotals[genderName],
    }));

  const sortedGenderTotals = genderTotalsArray.sort((a, b) => b.grandTotal - a.grandTotal);

  // console.log(sortedGenderTotals);

  let filterSummaryGenders = (
    <>
      {/*       <p className="gender-representation">Gender representation</p>
      <p>This will be a generic summary to show when not filtering</p> */}
    </>
  );
  let genderA = "";
  let genderATotal = 0;
  let genderB = "";
  let genderBTotal = 0;
  let genderC = "";
  let genderCTotal = 0;
  if (sortedGenderTotals.length === 1) {
    genderA = sortedGenderTotals[0].Gender;
    genderATotal = sortedGenderTotals[0].grandTotal;
    filterSummaryGenders = (
      <>
        <p className="gender-representation">Gender representation</p>
        <p>
          In your selection, {genderATotal} ({((genderATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} identify as {genderA}.
        </p>
      </>
    );
  } else if (sortedGenderTotals.length === 2) {
    genderA = sortedGenderTotals[0].Gender;
    genderATotal = sortedGenderTotals[0].grandTotal;
    genderB = sortedGenderTotals[1].Gender;
    genderBTotal = sortedGenderTotals[1].grandTotal;
    filterSummaryGenders = (
      <>
        <p className="gender-representation">Gender representation</p>
        <p>
          In your selection, {genderATotal} ({((genderATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} identify as {genderA}, compared to {genderBTotal} (
          {((genderBTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} who identify as {genderB}.
        </p>
      </>
    );
  } else if (sortedGenderTotals.length === 3) {
    genderA = sortedGenderTotals[0].Gender;
    genderATotal = sortedGenderTotals[0].grandTotal;
    genderB = sortedGenderTotals[1].Gender;
    genderBTotal = sortedGenderTotals[1].grandTotal;
    genderC = sortedGenderTotals[2].Gender;
    genderCTotal = sortedGenderTotals[2].grandTotal;
    filterSummaryGenders = (
      <>
        <p className="gender-representation">Gender representation</p>
        <p>
          In your selection, {genderATotal} ({((genderATotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} identify as {genderA}, compared to {genderBTotal} (
          {((genderBTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} who identify as {genderB} and {genderCTotal} (
          {((genderCTotal / newGrandTotal) * 100).toFixed(2)}%) {props.radioSelect.current} who identify as {genderC}.
        </p>
      </>
    );
  }

  return (
    <div className="report-summary-container">
      <p className="summary-title">Summary</p>

      <div className="report-summary">
        {/* {filterSummary} {filterSummaryPrograms} {filterSummarySectors} {filterSummaryGenders} */}
        {newGrandTotal === 0 ? (
          <p>Your selection does not apply to all the selected countries and therefore are not shown in the pie chart.</p>
        ) : (
          <React.Fragment>
            {filterSummary} {filterSummaryPrograms} {filterSummarySectors} {filterSummaryGenders}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default ReportSummary;
