import React from "react";
import { Chart, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

function PieChart({ countriesArray, radioSelect }) {
  // console.log(countriesArray);
  // console.log(radioSelect);
  const countryNames = [];
  const countryTotals = [];
  const countryPercentage = [];
  const countryColors = [];

  let newGrossTotal = 0;

  // Sort the countries array by highest total
  countriesArray = countriesArray.sort((a, b) => b.overallTotal - a.overallTotal);

  countriesArray.forEach((country) => {
    newGrossTotal += country.overallTotal;
  });

  // console.log(countriesArray);

  // console.log(newGrossTotal);
  countriesArray.forEach((country) => {
    // console.log(country.overallTotal);
    // console.log(country.overallPercentage);
    countryNames.push(country.name);
    countryTotals.push(country.overallTotal);
    countryPercentage.push((country.overallTotal / newGrossTotal) * 100);
    countryColors.push(country.countryColor);

    // if (country.overallTotal > 99 && country.overallTotal < 199) {
    //   countryColors.push("#E8112D");
    // } else if (country.overallTotal > 199 && country.overallTotal < 299) {
    //   countryColors.push("#C20E25");
    // } else if (country.overallTotal > 299 && country.overallTotal < 399) {
    //   countryColors.push("#90102A");
    // } else if (country.overallTotal > 399) {
    //   countryColors.push("#63102A");
    // } else {
    //   countryColors.push("#ED4E4E");
    // }
  });

  let genderTotals = {};

  function getTotalsForGenders(genderName, genderTotal) {
    if (genderName in genderTotals) {
      // Program already exists in programTotals, add programTotal to its existing total
      genderTotals[genderName] += genderTotal;
    } else {
      // Program does not exist in programTotals, create a new entry with programName as key and programTotal as value
      genderTotals[genderName] = genderTotal;
    }
  }

  // console.log(genderTotals);

  let programTotals = {};

  function getTotalsForPrograms(programName, programTotal) {
    if (programName in programTotals) {
      // Program already exists in programTotals, add programTotal to its existing total
      programTotals[programName] += programTotal;
    } else {
      // Program does not exist in programTotals, create a new entry with programName as key and programTotal as value
      programTotals[programName] = programTotal;
    }
  }

  // Used for the getTotalsForPrograms() function
  // START
  countriesArray.forEach((country) => {
    country.sectors.forEach((sector) => {
      sector.programs.forEach((program) => {
        let total =
          program.total.graduates.Male +
          program.total.graduates.Female +
          program.total.graduates.Unknown +
          program.total.participants.Male +
          program.total.participants.Female +
          program.total.participants.Unknown;
        let totalMales = program.total.graduates.Male + program.total.participants.Male;
        let totalFemales = program.total.graduates.Female + program.total.participants.Female;
        let totalUnknown = program.total.graduates.Unknown + program.total.participants.Unknown;
        getTotalsForGenders("Male", totalMales);
        getTotalsForGenders("Female", totalFemales);
        getTotalsForGenders("Unknown", totalUnknown);
        getTotalsForPrograms(program.program, total);
      });
    });
  });
  // END
  // console.log(programTotals["Digital Financial Services"]);

  let dfsTotals = 0;
  let dfisTotals = 0;
  let gecTotals = 0;
  let iipsTotals = 0;

  if (programTotals["Digital Financial Services"]) {
    dfsTotals = programTotals["Digital Financial Services"];
  }
  if (programTotals["Financial Regulation and Supervision"]) {
    dfisTotals = programTotals["Financial Regulation and Supervision"];
  }
  if (programTotals["Gender Equality"]) {
    gecTotals = programTotals["Gender Equality"];
  }
  if (programTotals["Instant and Inclusive Payment Systems"]) {
    iipsTotals = programTotals["Instant and Inclusive Payment Systems"];
  }

  function getTotalForSectorAndCountry(sectorName, country) {
    let total = 0;
    country.sectors.forEach((sector) => {
      if (sector.sector === sectorName) {
        sector.programs.forEach((program) => {
          total +=
            program.total.graduates.Male +
            program.total.graduates.Female +
            program.total.graduates.Unknown +
            program.total.participants.Male +
            program.total.participants.Female +
            program.total.participants.Unknown;
          //   let passTotal = total;
          // getTotalsForPrograms(program.program, passTotal);
        });
      }
    });
    return total;
  }

  let privateSectorTotal = 0;
  let publicSectorTotal = 0;
  let devSectorTotal = 0;
  let unkSectorTotal = 0;

  countriesArray.forEach((country) => {
    privateSectorTotal += getTotalForSectorAndCountry("Private", country);
    publicSectorTotal += getTotalForSectorAndCountry("Public", country);
    devSectorTotal += getTotalForSectorAndCountry("Development", country);
    unkSectorTotal += getTotalForSectorAndCountry("Unknown", country);
  });

  const totalSectors = privateSectorTotal + publicSectorTotal + devSectorTotal + unkSectorTotal;

  let privateSectorPercentage = (privateSectorTotal / totalSectors) * 100;
  let publicSectorPercentage = (publicSectorTotal / totalSectors) * 100;
  let devSectorPercentage = (devSectorTotal / totalSectors) * 100;
  let unkSectorPercentage = (unkSectorTotal / totalSectors) * 100;

  const totalPrograms = dfsTotals + dfisTotals + gecTotals + iipsTotals;

  // console.log(totalPrograms);

  let dfsPercentage = (dfsTotals / totalPrograms) * 100;
  let dfisPercentage = (dfisTotals / totalPrograms) * 100;
  let gecPercentage = (gecTotals / totalPrograms) * 100;
  let iipsPercentage = (iipsTotals / totalPrograms) * 100;

  // const totalPrograms

  let maleTotals = 0;
  let femaleTotals = 0;
  let unkTotals = 0;

  if (genderTotals["Male"]) {
    maleTotals = genderTotals["Male"];
  }
  if (genderTotals["Female"]) {
    femaleTotals = genderTotals["Female"];
  }
  if (genderTotals["Unknown"]) {
    unkTotals = genderTotals["Unknown"];
  }

  const totalGenders = maleTotals + femaleTotals + unkTotals;

  // console.log(totalGenders);

  let malePercentage = (maleTotals / totalGenders) * 100;
  let femalePercentage = (femaleTotals / totalGenders) * 100;
  let unkPercentage = (unkTotals / totalGenders) * 100;

  Chart.register(ArcElement, Tooltip);

  const data = {
    labels: [],
    datasets: [
      {
        id: 1,
        label: "Overall Countries",
        data: countryPercentage,
        dataTotoals: countryTotals,
        itemLabels: countryNames,
        backgroundColor: countryColors,
        borderColor: ["#ffffff"],
        borderWidth: 0.5,
      },
      {
        id: 2,
        label: "Capabilities",
        data: [dfsPercentage, dfisPercentage, gecPercentage, iipsPercentage],
        dataTotoals: [dfsTotals, dfisTotals, gecTotals, iipsTotals],
        itemLabels: ["DFS " + radioSelect.current, "DFIS " + radioSelect.current, "GEC " + radioSelect.current, "IIPS " + radioSelect.current],
        backgroundColor: ["#206caf", "#3a79b1", "#81b1db", "#589dd9"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
      {
        id: 3,
        label: "Sectors",
        data: [privateSectorPercentage, publicSectorPercentage, devSectorPercentage, unkSectorPercentage],
        dataTotoals: [privateSectorTotal, publicSectorTotal, devSectorTotal, unkSectorTotal],
        itemLabels: ["Private", "Public", "Development", "Unknown"],
        backgroundColor: ["#ACE575", "#87C24E", "#668449", "#68A031"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
      {
        id: 4,
        label: "Genders",
        data: [malePercentage, femalePercentage, unkPercentage],
        dataTotoals: [maleTotals, femaleTotals, unkTotals],
        itemLabels: ["Male", "Female", "Non-Binary and Undisclosed"],
        backgroundColor: ["#F6B63C", "#FAC96F", "#FAE56F"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        intersect: true,
        mode: "nearest",
        callbacks: {
          title: (item) => item[0].dataset.label,
          label: (item) => " " + item.dataset.itemLabels[item.dataIndex] + ": " + item.dataset.dataTotoals[item.dataIndex] + " | " + item.parsed.toFixed(1) + "%",
        },
      },
    },
  };

  return (
    <Pie
      datasetIdKey="id"
      type="pie"
      data={data}
      options={options}
    />
  );
}
export default PieChart;
