import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FilterDropdownList.scss";
import ProcessQueryParms from "../../utils/ProcessQueryParams";
library.add(faChevronDown);
function FilterDropdownList(props) {
  const [CheckedItem, setCheckedItem] = useState([]);

  // Let's get the query parameters if they exist
  const hasQueryParams = ProcessQueryParms();

  // const capabilitiesOptions = ["Digital Financial Services", "Financial Regulation and Supervision", "Gender Equality", "Instant and Inclusive Payment Systems"];
  const capabilitiesOptions = props.capabilitiesArray;
  // const sectorOptions = ["Private", "Public", "Development", "Unknown"];
  const sectorOptions = props.sectorsArray;

  // const genderOptions = ["Male", "Female", "Other"];
  const genderOptions = props.gendersArray;

  let dropdownItemsArray = [];

  // console.log(props.countriesArray);
  // console.log(props.totals)

  // Set the type for the dropdown
  let dropdownToggleText;
  switch (props.dropdownType) {
    case "countries":
      dropdownToggleText = "Countries";
      dropdownItemsArray = props.countriesArray;
      break;
    case "capabilities":
      dropdownToggleText = "Capabilities";
      dropdownItemsArray = capabilitiesOptions;
      break;
    case "sectors":
      dropdownToggleText = "Sectors";
      dropdownItemsArray = sectorOptions;
      break;
    case "gender":
      dropdownToggleText = "Gender";
      dropdownItemsArray = genderOptions;
      break;
    default:
      dropdownToggleText = "Options";
      dropdownItemsArray = [];
  }

  // This isn't needed anymore. Conflicting with the query
  const handleCheckboxChange = (event) => {
    // const isChecked = event.target.checked;
    // const continent = event.target.id;
    // const isContinent = props.continents.includes(continent);
    // if (isContinent) {
    //   if (isChecked) {
    //     setCheckedItem((prevCheckedItem) => [...prevCheckedItem, continent]);
    //   } else {
    //     setCheckedItem((prevCheckedItem) => prevCheckedItem.filter((c) => c !== continent));
    //   }
    // } else {
    //   if (isChecked) {
    //     setCheckedItem((prevCheckedItem) => [...prevCheckedItem, continent]);
    //   } else {
    //     setCheckedItem((prevCheckedItem) => prevCheckedItem.filter((c) => c !== continent));
    //   }
    // }
  };

  let addContinent = "";
  let isContinent = false;
  const dropdownItems = dropdownItemsArray.map((item, index) => {
    let itemTotal = 0;
    let optionClass = "default";
    let isDisabled = false;
    let forIsChecked = item;
    // console.log(item)
    if (props.dropdownType === "countries") {
      // Only do this on the countries dropdown
      // console.log(item);
      itemTotal = parseInt(item.split("+")[1]);
      item = item.split("+")[0];
      if (itemTotal === 0) {
        isDisabled = true;
      }
      optionClass = "country";
      if (props.continents.includes(item)) {
        isContinent = true;
        addContinent = item;
        optionClass = "continent";
      }
    }

    // This is a special condition for the countries dropdown but only when a continent is selected
    if (isContinent && !CheckedItem.includes(item)) {
      forIsChecked = addContinent;
    }

    let isChecked = CheckedItem.includes(forIsChecked);

    // Filter using the queryParameters
    // Or rather, just set the boxes as checked
    if (hasQueryParams) {
      // console.log(hasQueryParams[1]);
      if (hasQueryParams[1].includes(item)) {
        isChecked = true;
      }
    }

    return (
      <Form.Check
        key={item}
        disabled={isDisabled}
        continent={addContinent}
        checked={isChecked}
        type="checkbox"
        id={item}
        label={item === "Other" ? "Non-Binary and Undisclosed" : item}
        className={optionClass}
        onChange={handleCheckboxChange}
      />
    );
  });

  return (
    <Dropdown autoClose={true}>
      <Dropdown.Toggle
        id="dropdown-autoclose-false"
        className="dropdown-toggle select-header"
      >
        {dropdownToggleText}
        <FontAwesomeIcon icon="chevron-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu id={props.dropdownType}>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterDropdownList;
