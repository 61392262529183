function CreateQueryParams(filterType, filterItem, countries) {
  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;

  // Get the existing value for the query parameter
  const existingParams = searchParams.get(filterType);
  const continents = ["Asia", "Africa", "North America", "South America", "Europe", "Oceania", "Unknown Continent"];

  // Do something so this doesn't update on every change
  let countriesForFilter = [];
  countries.forEach((country) => {
    const existingCountry = countriesForFilter.find((c) => c.country === country.Country && c.continent === country.Continent);
    if (!existingCountry) {
      countriesForFilter.push({ country: country.Country, continent: country.Continent });
    }
  });

  let commaSeparatedString = [];
  // First let's check if it's a continent or not
  if (continents.includes(filterItem)) {
    if (existingParams && existingParams.includes(filterItem)) {
      console.log('look here');
      const countriesArray = countriesForFilter.filter((country) => country.continent === filterItem);
      const countryNames = countriesArray.map((country) => country.country);
      const params = existingParams.split(",");
      // Remoe the countries if they exist
      const filteredParams = params.filter((param) => !countryNames.includes(param));

      // Remove empty items that sometimes get added - this line is breaking stuff
      // filteredParams.splice(0, filteredParams.length - 1);
      // console.log(filteredParams);
      commaSeparatedString = filteredParams.join(",");
      console.log(commaSeparatedString);
    } else {
      // If it's a continent and it doesn't exist yet, add it's countries
      const countriesArray = countriesForFilter.filter((country) => country.continent === filterItem);
      const countryNames = countriesArray.map((country) => country.country);
      commaSeparatedString = countryNames.join(",");
    }
  }

  // console.log(commaSeparatedString);
  // Check if the filterItem already exists in the query parameter and remove it
  if (existingParams && existingParams.includes(filterItem)) {
    let params;
    let removeContinent = false;
    // console.log(filterType);
    if (filterType === "countries") {
      // Look for the continent of the selected country
      console.log(filterItem);
      const countryContinent = countriesForFilter.filter((country) => country.country === filterItem);
      console.log(countryContinent);
      if (countryContinent.length !== 0) {
        params = existingParams.split(",");
        const continentRemoved = params.filter((param) => param !== countryContinent[0].continent);
        // console.log(countryContinent[0].continent);
        params = continentRemoved;
        removeContinent = true;
      }
    }

    if (removeContinent === false) {
      if (commaSeparatedString.length !== 0) {
        params = commaSeparatedString.split(",");
      } else {
        params = existingParams.split(",");
      }
    }

    const updateParams = params.filter((item) => item !== filterItem);

    const updatedString = updateParams.join(",");

    if (updatedString.length === 0) {
      searchParams.delete(filterType);
    } else {
      searchParams.set(filterType, updatedString);
    }
  } else {
    let updatedParams;
    // console.log(existingParams);
    // Construct the updated value for the query parameter
    if (commaSeparatedString.length !== 0) {
      updatedParams = existingParams ? `${existingParams},${filterItem},${commaSeparatedString}` : `${filterItem},${commaSeparatedString}`;
    } else {
      updatedParams = existingParams ? `${existingParams},${filterItem}` : `${filterItem}`;
    }

    // Set the updated value for the query parameter
    searchParams.set(filterType, updatedParams);
  }

  // console.log(searchParams);

  const originalString = searchParams.toString();
  const replacedString = originalString.replace(/\+/g, "%20");

  // console.log(replacedString);
  // Update the search portion of the URL
  currentUrl.search = replacedString;

  // Replace the current URL with the updated one
  window.history.replaceState({}, "", currentUrl.href);
}

export default CreateQueryParams;
