import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import "./ToggleViewButton.scss";

function ToggleViewButton({ fetchCountryHandler }) {
  const [radioValue, setRadioValue] = useState("participants");

  const radios = [
    { name: "participants", value: "participants" },
    { name: "graduates", value: "graduates" },
  ];

  function typeFiltering(radioType) {
    setRadioValue(radioType);
    fetchCountryHandler("", "", radioType);
  }

  return (
    <div className="toggle-wrapper">
      <Row>
        <Col sm={12}>
          <p className="toggle-title">DATA VIEW</p>
        </Col>
        <Col
          xl={12}
          className="pe-0"
        >
          {/* <p>
            For the purposes of this report, participants are anyone who achieved a pass or fail results in one of our courses. Graduates achieved a pass on the relevant Capstone course in the Digital Financial Inclusion Supervision, Gender Equality Changemaker, orInstant and Inclusive Payment Systems programmes, or achieved 110 academic credits and 20 practical credits in the CDFP portfolio. 
          </p> */}

          <p>
            *Participant view: total course participants, regardless of final result (pass or fail) <br /> *Graduate view: individuals who fulfil the requirements for
            certification
          </p>
        </Col>
        <Col
          xl={12}
          className="my-auto text-end"
        >
          <ButtonGroup className="mb-2">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(selected) => typeFiltering(selected.currentTarget.value)}
                className={"toggle-view-button"}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

export default ToggleViewButton;
