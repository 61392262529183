function ProcessQueryParms() {
  // Get the query string from the current URL
  const queryString = window.location.search;

  // Remove the leading '?' character
  const trimmedQueryString = queryString.substring(1);

  // Split the query string into an array of key-value pairs
  const keyValuePairs = trimmedQueryString.split("&");

  // Create an object to store the parameters
  const params = {};

  // Loop through each key-value pair and populate the object
  keyValuePairs.forEach((keyValuePair) => {
    const [key, value] = keyValuePair.split("=");
    params[key] = decodeURIComponent(value);
  });

  const filterTypes = [];
  const filterItems = [];

  // Iterate over all the parameters
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      filterTypes.push(key);
      const splitValue = value.split(",");
      splitValue.forEach((item) => {
        filterItems.push(item);
      });
    }
  }
  // console.log(filterTypes);
  // console.log(filterItems);
  // console.log(keyValuePairs);
  if (queryString) {
    return [filterTypes, filterItems];
  } else {
    return false;
  }
}

export default ProcessQueryParms;
