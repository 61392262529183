import React, { useState, useEffect, useCallback, useRef } from "react";
import { Col, Container, Row, Button, Spinner, Modal, InputGroup } from "react-bootstrap";
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";
import "./App.scss";

import ReportFilterForm from "./components/forms/ReportFilterForm";
import MultiSeriesPieChart from "./components/charts/MultiSeriesPieChart";
// import CapacityReportTable from "./components/capacity-report-table/CapacityReportTable";
import CapacityReportMap from "./components/capacity-report-map/CapacityReportMap";
import ReportSummary from "./components/report-summary/ReportSummary";
import ShareReportButton from "./components/buttons/ShareReportButton";
import ToggleViewButton from "./components/buttons/ToggleViewButton";
import ProcessQueryParms from "./utils/ProcessQueryParams";
import CapacityReportTableV2 from "./components/capacity-report-table/CapacityReportTableV2";

import PrintPreview from "./print-setings.png";

// import { renderToString } from "react-dom/server";
// import { Document, Page, Text } from "@react-pdf/renderer";

function App() {
  // For exporting as PDF
  const printRef = React.useRef();

  const [showPrintModal, setShowPrintModal] = useState(false);

  const handleCloseModal = () => setShowPrintModal(false);
  const handleShowModal = () => setShowPrintModal(true);

  const [countries, setCountries] = useState([]);
  const [grossTotal, setGrossTotal] = useState(0); // This will change on every fetch
  const [globalTotal, setGlobalTotal] = useState(0); // This one stays the same. Only changes when switching between Participants and Graduates
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterMessage, setFilterMessage] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [capabilitiesArray, setCapabilitiesArray] = useState([]);
  const [capabilitiesTotalsArray, setCapabilitiesTotalsArray] = useState([]);
  const [sectorsArray, setSectorsArray] = useState([]);
  const [gendersArray, setGendersArray] = useState([]);

  const [viewTypes, setViewTypes] = useState([]);

  const radioSelect = useRef("participants");
  const filterSelect = useRef([]);
  const typesSelect = useRef([]);
  // const handleDownloadPdf = async () => {
  //   setIsLoading(true);
  //   try {
  //     const element = printRef.current;
  //     const canvas = await html2canvas(element, {
  //       windowWidth: element.scrollWidth,
  //       windowHeight: element.scrollHeight,
  //     });
  //     console.log(element.scrollHeight);
  //     const data = canvas.toDataURL("image/png");

  //     const pdf = new jsPDF();
  //     const imgProperties = pdf.getImageProperties(data);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //     pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("dfi-capacity-report.pdf");
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // console.log(hasQueryParams);

  const fetchCountryHandler = useCallback(async (selected, types, radioTypeSelected) => {
    // Let's get the query parameters if they exist
    const hasQueryParams = ProcessQueryParms();

    types = hasQueryParams[0];
    selected = hasQueryParams[1];

    // Make sure the selected variable is defined
    // if (selected === undefined) {
    //   selected = [];
    // }
    // console.log(selected);
    if (radioTypeSelected) {
      radioSelect.current = radioTypeSelected;
    } else {
      filterSelect.current = selected;
      typesSelect.current = types;
      // selected = filterSelect.current;
    }

    if (selected && types) {
      setViewTypes(types.concat(selected));
    } else {
      selected = filterSelect.current;
      types = typesSelect.current;
    }
    // setViewTypes(types.concat(selected));
    // console.log(selected);
    let grossTotal = 0;
    // setIsLoading(true);
    setError(null);
    try {
      // const response = await fetch("./dfi-capacity-report-data-final-2023.json");
      const response = await fetch("https://tableau.digitalfrontiersinstitute.org:9443/xml/query_file.php?target_file=Impact");
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();

      const countries = data.map((country) => {
        if (country.Gender !== "Male" && country.Gender !== "Female") {
          return { ...country, Gender: "Unknown" };
        }
        if (country.Country === "Unknown") {
          // console.log(country);

          return { ...country, Country: "Unknown Country", Continent: "Unknown Continent" };
        }
        return country;
      });

      // THIS IS NEW CODE
      let programs = [];
      let sectors = [];
      let genders = [];
      let capabilitiesTotals = [];
      // let capabilitiesTotals = [];
      // Deconstruct the data and create unique arrays for everything
      data.forEach((item) => {
        // console.log(item);
        // Combine all other gender options into one
        // I call this Other so we don't conflict with the unknown Country
        if (item.Gender !== "Male" && item.Gender !== "Female") {
          item.Gender = "Other";
        }

        if (!programs.includes(item.Program)) {
          programs.push(item.Program);
        }

        if (!sectors.includes(item.Sector)) {
          sectors.push(item.Sector);
        }

        if (!genders.includes(item.Gender)) {
          genders.push(item.Gender);
        }

        const programIndex = capabilitiesTotals.findIndex((obj) => obj.program === item.Program);
        if (programIndex === -1) {
          // Program not found in capabilitiesTotals, add a new entry
          capabilitiesTotals.push({
            program: item.Program,
            total: item.Total_participants,
          });
        } else {
          // Program found in capabilitiesTotals, update the total
          capabilitiesTotals[programIndex].total += item.Total_participants;
        }
      });
      // THIS IS NEW CODE

      // console.log(countries);
      // console.log(countries);
      const transformedData = Object.entries(
        countries.reduce((acc, { Program, Sector, Gender, Country, Continent, Total_participants, Total_graduates }) => {
          if (!acc[Country]) {
            acc[Country] = { continent: Continent, sectors: {} };
          }
          if (!acc[Country].sectors[Sector]) {
            acc[Country].sectors[Sector] = { programs: {} };
          }
          if (!acc[Country].sectors[Sector].programs[Program]) {
            acc[Country].sectors[Sector].programs[Program] = { participants: { Male: 0, Female: 0, Unknown: 0 }, graduates: { Male: 0, Female: 0, Unknown: 0 } };
          }
          if (radioSelect.current === "participants") {
            acc[Country].sectors[Sector].programs[Program]["participants"][Gender] += Total_participants;
          } else if (radioSelect.current === "graduates") {
            acc[Country].sectors[Sector].programs[Program]["graduates"][Gender] += Total_graduates;
          }
          return acc;
        }, {})
      ).map(([country, { continent, sectors }]) => ({
        country,
        continent: continent,
        sectors: Object.entries(sectors).map(([sector, { programs }]) => ({
          sector,
          programs: Object.entries(programs).map(([program, total]) => ({
            program,
            total,
          })),
        })),
      }));

      transformedData.forEach((country) => {
        var countryTotal = 0;
        country.sectors.forEach((sector) => {
          var sectorTotal = 0;
          sector.programs.forEach((program) => {
            var MalesPerProgram = Number(program.total.graduates.Male) + Number(program.total.participants.Male);
            var FemalesPerProgram = Number(program.total.graduates.Female) + Number(program.total.participants.Female);
            var UnknownPerProgram = Number(program.total.graduates.Unknown) + Number(program.total.participants.Unknown);
            var totals = MalesPerProgram + FemalesPerProgram + UnknownPerProgram;
            sectorTotal += totals;
          });
          countryTotal += sectorTotal;
        });
        grossTotal += countryTotal;
      });
      // console.log(grossTotal);
      setCountryList(transformedData);
      // console.log(transformedData)
      setGrossTotal(grossTotal);
      setGlobalTotal(grossTotal);
      // Filter out countries that are not in the selected array

      // if (types) {
      //   console.log("types");
      //   if (types.length !== 0 && selected.length === 0) {
      //     types = [];
      //     console.log("YES");
      //   }
      // }
      // console.log(types);
      // console.log(selected);
      if (selected !== undefined) {
        if (selected.length > 0) {
          // let filteredArray = [];
          // console.log(types);
          // console.log(transformedData[0]);
          // Reset the total when filtering
          grossTotal = 0;

          const filteredData = transformedData.map((element) => {
            let newElement = {};
            let sectors = element.sectors;
            let programs;
            // These work on individual dropdowns
            if (types.length === 1) {
              // Only for sectors
              if (types[0] === "sectors") {
                sectors = element.sectors.filter((sector) => selected.includes(sector.sector));
                // console.log(element.sectors.filter((sector) => selected.includes(sector.sector)));
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // Only for Capabilities (Programs)
              if (types[0] === "capabilities") {
                sectors = [];
                element.sectors.forEach((sector) => {
                  sector = { sector: sector.sector, programs: sector.programs.filter((program) => selected.includes(program.program)) };
                  sectors.push(sector);
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // Only for genders
              if (types[0] === "gender") {
                sectors = [];
                element.sectors.forEach((sector) => {
                  programs = [];
                  sector.programs.forEach((program) => {
                    let participants = {};
                    let graduates = {};
                    if (selected.includes("Male")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                    }
                    if (selected.includes("Female")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    if (selected.includes("Other")) {
                      participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes male and female
                    if (selected.includes("Male") && selected.includes("Female")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    // if selected includes male and unknown
                    if (selected.includes("Male") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes female and unknown
                    if (selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }

                    if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }

                    programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                  });
                  sectors.push({ sector: sector.sector, programs: programs });
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // Only for Countries
              if (types[0] === "countries") {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }
            }
            // These work when there are two dropdowns selected
            if (types.length === 2) {
              // When country and Sectors are selected
              if (types.includes("countries") && types.includes("sectors")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = element.sectors.filter((sector) => selected.includes(sector.sector));
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }

              // When country and Programs are selected - DON'T CONFUSE PROGRAMS AND CAPABILITIES. THEY ARE THE SAME THING!!
              if (types.includes("countries") && types.includes("capabilities")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = [];
                  element.sectors.forEach((sector) => {
                    sector = { sector: sector.sector, programs: sector.programs.filter((program) => selected.includes(program.program)) };
                    sectors.push(sector);
                  });
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }

              // If Country and genders are selected
              if (types.includes("countries") && types.includes("gender")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = [];
                  element.sectors.forEach((sector) => {
                    programs = [];
                    sector.programs.forEach((program) => {
                      let participants = {};
                      let graduates = {};
                      if (selected.includes("Male")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                      }
                      if (selected.includes("Female")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      if (selected.includes("Other")) {
                        participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes male and female
                      if (selected.includes("Male") && selected.includes("Female")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      // if selected includes male and unknown
                      if (selected.includes("Male") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes female and unknown
                      if (selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }

                      if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }

                      programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                    });
                    sectors.push({ sector: sector.sector, programs: programs });
                  });
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }

              // if sectors and genders are selected
              if (types.includes("sectors") && types.includes("gender")) {
                sectors = [];
                element.sectors.forEach((sector) => {
                  programs = [];
                  sector.programs.forEach((program) => {
                    let participants = {};
                    let graduates = {};
                    if (selected.includes("Male")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                    }
                    if (selected.includes("Female")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    if (selected.includes("Other")) {
                      participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes male and female
                    if (selected.includes("Male") && selected.includes("Female")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    // if selected includes male and unknown
                    if (selected.includes("Male") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes female and unknown
                    if (selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }

                    if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }

                    programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                  });
                  if (selected.includes(sector.sector)) {
                    sectors.push({ sector: sector.sector, programs: programs });
                  }
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // If capabilities and genders are selected
              if (types.includes("capabilities") && types.includes("gender")) {
                sectors = [];
                element.sectors.forEach((sector) => {
                  programs = [];
                  sector.programs.forEach((program) => {
                    let participants = {};
                    let graduates = {};
                    if (selected.includes("Male")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                    }
                    if (selected.includes("Female")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    if (selected.includes("Other")) {
                      participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes male and female
                    if (selected.includes("Male") && selected.includes("Female")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                    }
                    // if selected includes male and unknown
                    if (selected.includes("Male") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                    }

                    // if selected includes female and unknown
                    if (selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }

                    if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                      participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                      graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                    }
                    if (selected.includes(program.program)) {
                      programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                    }
                  });
                  sectors.push({ sector: sector.sector, programs: programs });
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // If capabilities and sectors are selected
              if (types.includes("capabilities") && types.includes("sectors")) {
                sectors = [];
                element.sectors.forEach((sector) => {
                  if (selected.includes(sector.sector)) {
                    sectors.push({ sector: sector.sector, programs: sector.programs.filter((program) => selected.includes(program.program)) });
                  }
                  // sector = { sector: sector.sector, programs: sector.programs.filter((program) => selected.includes(program.program)) };
                  // sectors.push(sector);
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }
            }

            // this works when there are three dropdowns selected
            if (types.length === 3) {
              // When Country, Sectors and Programs are selected
              if (types.includes("countries") && types.includes("sectors") && types.includes("capabilities")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = [];
                  sectors = element.sectors.filter((sector) => selected.includes(sector.sector));
                  sectors.forEach((sector) => {
                    if (selected.includes(sector.sector)) {
                      sectors = [];
                      sector = { sector: sector.sector, programs: sector.programs.filter((program) => selected.includes(program.program)) };
                      sectors.push(sector);
                    }
                    newElement = { country: element.country, continent: element.continent, sectors: sectors };
                  });
                }
              }

              // When Sectors, programs and genders are selected
              if (types.includes("sectors") && types.includes("capabilities") && types.includes("gender")) {
                sectors = [];
                element.sectors.forEach((sector) => {
                  programs = [];
                  if (selected.includes(sector.sector)) {
                    sector.programs.forEach((program) => {
                      let participants = {};
                      let graduates = {};
                      if (selected.includes("Male")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                      }
                      if (selected.includes("Female")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      if (selected.includes("Other")) {
                        participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes male and female
                      if (selected.includes("Male") && selected.includes("Female")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      // if selected includes male and unknown
                      if (selected.includes("Male") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes female and unknown
                      if (selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }

                      if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }
                      if (selected.includes(program.program)) {
                        programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                      }
                    });
                  }
                  sectors.push({ sector: sector.sector, programs: programs });
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }

              // When Countrie, Sectors and Genders are selected
              if (types.includes("countries") && types.includes("sectors") && types.includes("gender")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = [];
                  element.sectors.forEach((sector) => {
                    programs = [];
                    if (selected.includes(sector.sector)) {
                      sector.programs.forEach((program) => {
                        let participants = {};
                        let graduates = {};
                        if (selected.includes("Male")) {
                          participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                          graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                        }
                        if (selected.includes("Female")) {
                          participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                          graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                        }
                        if (selected.includes("Other")) {
                          participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                          graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                        }

                        // if selected includes male and female
                        if (selected.includes("Male") && selected.includes("Female")) {
                          participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                          graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                        }
                        // if selected includes male and unknown
                        if (selected.includes("Male") && selected.includes("Other")) {
                          participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                          graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                        }

                        // if selected includes female and unknown
                        if (selected.includes("Female") && selected.includes("Other")) {
                          participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                          graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                        }

                        if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                          participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                          graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                        }
                        programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                      });
                    }
                    sectors.push({ sector: sector.sector, programs: programs });
                  });
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }

              // When Countries, Programs and Genders are selected
              if (types.includes("countries") && types.includes("capabilities") && types.includes("gender")) {
                if (selected.includes(element.country) || selected.includes(element.continent)) {
                  sectors = [];
                  element.sectors.forEach((sector) => {
                    programs = [];

                    sector.programs.forEach((program) => {
                      let participants = {};
                      let graduates = {};
                      if (selected.includes("Male")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                      }
                      if (selected.includes("Female")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      if (selected.includes("Other")) {
                        participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes male and female
                      if (selected.includes("Male") && selected.includes("Female")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      // if selected includes male and unknown
                      if (selected.includes("Male") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes female and unknown
                      if (selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }

                      if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }
                      if (selected.includes(program.program)) {
                        programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                      }
                    });

                    sectors.push({ sector: sector.sector, programs: programs });
                  });
                  newElement = { country: element.country, continent: element.continent, sectors: sectors };
                }
              }
            }

            // This works when all four dropdowns are selected
            if (types.length === 4) {
              if (selected.includes(element.country) || selected.includes(element.continent)) {
                sectors = [];
                element.sectors.forEach((sector) => {
                  programs = [];
                  if (selected.includes(sector.sector)) {
                    sector.programs.forEach((program) => {
                      let participants = {};
                      let graduates = {};
                      if (selected.includes("Male")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: 0 };
                      }
                      if (selected.includes("Female")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      if (selected.includes("Other")) {
                        participants = { Male: 0, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes male and female
                      if (selected.includes("Male") && selected.includes("Female")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: 0 };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: 0 };
                      }
                      // if selected includes male and unknown
                      if (selected.includes("Male") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: 0, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: 0, Unknown: program.total.graduates.Unknown };
                      }

                      // if selected includes female and unknown
                      if (selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: 0, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: 0, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }

                      if (selected.includes("Male") && selected.includes("Female") && selected.includes("Other")) {
                        participants = { Male: program.total.participants.Male, Female: program.total.participants.Female, Unknown: program.total.participants.Unknown };
                        graduates = { Male: program.total.graduates.Male, Female: program.total.graduates.Female, Unknown: program.total.graduates.Unknown };
                      }
                      if (selected.includes(program.program)) {
                        programs.push({ program: program.program, total: { participants: participants, graduates: graduates } });
                      }
                    });
                  }
                  sectors.push({ sector: sector.sector, programs: programs });
                });
                newElement = { country: element.country, continent: element.continent, sectors: sectors };
              }
            }

            // newElement = { country: element.country, continent: element.continent, sectors: sectors };
            return newElement;
          });

          // Check if there should be a warning when filtering or not

          filteredData.forEach((elements) => {
            let totalParticipants = 0;
            let totalGraduates = 0;
            if (Object.keys(elements).length !== 0) {
              let checkHasValue = true;
              elements.sectors.forEach((sector) => {
                if (sector.programs.length === 0) {
                  checkHasValue = false;
                }
                // console.log(sector);
                sector.programs.forEach((program) => {
                  totalParticipants += program.total.participants.Male + program.total.participants.Female + program.total.participants.Unknown;
                  totalGraduates += program.total.graduates.Male + program.total.graduates.Female + program.total.graduates.Unknown;
                });
              });
              if (totalParticipants === 0 && totalGraduates === 0) {
                checkHasValue = false;
                grossTotal = 0;
              }
              if (checkHasValue === false) {
                setFilterMessage(true);
              } else {
                setFilterMessage(false);
              }
              grossTotal += totalParticipants + totalGraduates;
            }
          });
          setGrossTotal(grossTotal);
          setCountries(filteredData);
        } else {
          setFilterMessage(false);
          setCountries(transformedData);
        }
        setRawData(countries);
        setCapabilitiesArray(programs);
        setSectorsArray(sectors);
        setGendersArray(genders);
        setCapabilitiesTotalsArray(capabilitiesTotals);
      } else {
        setFilterMessage(false);
        setCountries(transformedData);
        // THIS IS NEW CODE
        setRawData(countries);
        setCapabilitiesArray(programs);
        setSectorsArray(sectors);
        setGendersArray(genders);
        setCapabilitiesTotalsArray(capabilitiesTotals);

        // THIS IS NEW CODE
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCountryHandler();
  }, [fetchCountryHandler]);

  let countriesArray = [];
  let countriesArrayList = [];

  // if(isNaN(grossTotal)) {
  //   setGrossTotal(0);
  // }

  let counter = 0;
  function generateColor(key) {
    const hue = counter % 360; // generate a hue value based on the key
    counter += 25;
    return `hsl(${hue.toFixed(0)}, 50%, 50%)`; // convert the hue to an HSL color value
  }

  if (countries.length > 0) {
    countries.forEach((country) => {
      let MalesPerProgram = 0;
      let FemalesPerProgram = 0;
      let UnknownPerProgram = 0;
      // console.log(country.programs);
      var countryTotal = 0;
      if (country.sectors) {
        country.sectors.forEach((sector) => {
          var sectorTotal = 0;
          sector.programs.forEach((program) => {
            MalesPerProgram = Number(program.total.graduates.Male) + Number(program.total.participants.Male);
            FemalesPerProgram = Number(program.total.graduates.Female) + Number(program.total.participants.Female);
            UnknownPerProgram = Number(program.total.graduates.Unknown) + Number(program.total.participants.Unknown);
            var totals = MalesPerProgram + FemalesPerProgram + UnknownPerProgram;
            sectorTotal += Number(totals);
          });
          countryTotal += sectorTotal;
          sector.total = sectorTotal;
        });
        countriesArray.push({
          id: country.country.replace(/[^A-Z0-9]+/gi, "_"),
          name: country.country,
          continent: country.continent,
          sectors: country.sectors,
          overallTotal: isNaN(countryTotal) ? 0 : countryTotal,
          overallPercentage: isNaN(grossTotal) || grossTotal === 0 ? 0 : (countryTotal / grossTotal) * 100,
          countryColor: generateColor(countryTotal),
        });
      }
    });

    countryList.forEach((country) => {
      // console.log(country);
      countriesArrayList.push({
        id: country.country.replace(/[^A-Z0-9]+/gi, "_"),
        name: country.country,
        continent: country.continent,
        countryColor: country.countryColor,
        countryTotal: country.countryTotal,
      });
    });
  }

  // console.log(countriesArray);
  // console.log(rawData);

  if (error) {
    // options = <option>{error}</option>;
  }

  if (isLoading) {
    // options = <option>Loading...</option>;
    // console.log("Loading...");
  } else {
    // console.log('Done loading');
  }

  const handleReset = () => {
    // Get the current URL
    var currentURL = window.location.href;

    // Remove search parameters
    var cleanURL = currentURL.split("?")[0];

    // Redirect to the clean URL
    window.location.href = cleanURL;
  };

  const handlePrint = () => {
    const mediaQueryList = window.matchMedia("print");

    const printOptions = {
      landscape: false,
      scale: 0.8,
    };

    const printEventHandler = (e) => {
      if (e.matches) {
        window.document.body.style.backgroundColor = "#FFF"; // Change background color before printing
      } else {
        window.document.body.style.backgroundColor = ""; // Reset background color after printing
      }
    };

    mediaQueryList.addListener(printEventHandler);

    window.print(printOptions);

    mediaQueryList.removeListener(printEventHandler);
  };

  // const handleDownload = () => {
  //   const PDFDocument = () => (
  //     <Document>
  //       <Page>
  //         <Text>Rendered Component:</Text>
  //       </Page>
  //     </Document>
  //   );

  //   const pdfString = renderToString(<PDFDocument />);
  //   const pdfBlob = new Blob([pdfString], { type: 'application/pdf' });
  //   const url = URL.createObjectURL(pdfBlob);

  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = 'example.pdf';
  //   link.click();

  //   URL.revokeObjectURL(url);
  // };

  return (
    <div
      id="capacity-build-report"
      className="App"
    >
      <Container fluid>
        <Row className="mt-5">
          <Col md={12}>
            <h2 className="mb-4">Digital Frontiers Built Capacity</h2>
          </Col>
          <Col
            md={6}
            className="d-print-none d-block"
          >
            <p className="how-it-works-title">How to use the Capacity Report</p>
            <p>
              At Digital Frontiers we believe in building capabilities and making human connections, but more than that, building capabilities and connections for good. The report
              below illustrates the capacity Digital Frontiers has developed in more than 166 countries, broken down by capability, sector and gender.
            </p>
            <p>
              The interactive pie chart shows countries in the outer ring, with capabilities and sectors in the middle two rings and gender in the inner ring. A text summary
              provides a brief overview of the selections you have made. Below the chart, the same data is summarised in a table for easier interpretation.
            </p>
            <p>
              The initial view shows the results for all countries, illustrating Digital Frontiers' extensive reach across borders and regions. Start selecting countries from the
              list to update the chart and table, then choose from the list of capabilities, sectors and genders to further narrow down the results. You may end up selecting a
              combination of filters that returns no results – just click the reset button to start again.
            </p>
          </Col>
          <Col
            md={6}
            className="d-print-none d-block"
          >
            <ToggleViewButton fetchCountryHandler={fetchCountryHandler} />
          </Col>
          <Col
            md={12}
            className="d-none d-print-block"
          >
            <div className={"pdf-header-wrapper"}>
              <p>To view the full data set, users can click on the button in this section to go to the live Capacity data on the site.</p>
              <a
                href={`${window.location.href}`}
                className="btn btn-primary"
              >
                View entire data set here
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div ref={printRef}>
        <Container fluid>
          <Row className="mt-5">
            <Col
              md={6}
              lg={4}
            >
              <MultiSeriesPieChart
                countriesArray={countriesArray}
                grossTotal={grossTotal}
                radioSelect={radioSelect}
              />
            </Col>
            <Col
              md={6}
              lg={8}
            >
              <Row className="mb-3">
                <Col sm={9}>
                  {filterMessage && (
                    <p className="filter-message">Your selection does not apply to all the selected countries, and the results will not be shown in the pie chart.</p>
                  )}
                </Col>
                <Col
                  sm={3}
                  className="d-print-none d-block"
                >
                  <Button
                    className="float-end reset-button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>

              <ReportFilterForm
                fetchCountryHandler={fetchCountryHandler}
                countriesArray={countriesArray}
                countriesArrayList={countriesArrayList}
                sectorsArray={sectorsArray}
                capabilitiesArray={capabilitiesArray}
                gendersArray={gendersArray}
                rawData={rawData}
              />
              {/* {console.log(countryNames)} */}
            </Col>
          </Row>

          <Row>
            <Col
              md={4}
              className="mt-5"
            >
              <p className="text-center">
                <Button
                  type="button"
                  variant="primary"
                  className="btn"
                  onClick={handleShowModal}
                >
                  {/* Save as PDF */}
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save as PDF"
                  )}
                </Button>
              </p>

              {/* <div>
                <button onClick={handlePrint}>Save as PDF</button>
              </div> */}

              <ShareReportButton />
            </Col>
            <Col md={8}>
              <ReportSummary
                countriesArray={countriesArray}
                filterSelect={filterSelect}
                radioSelect={radioSelect}
              />
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row className="mt-5">
            <Col>
              {/* THIS IS A NEW COMPONENT */}
              <CapacityReportTableV2
                viewTypes={viewTypes}
                rawData={rawData}
                gendersArray={gendersArray}
                capabilitiesArray={capabilitiesArray}
                sectorsArray={sectorsArray}
                countriesArray={countriesArray}
                grossTotal={grossTotal}
                globalTotal={globalTotal}
                capabilitiesTotals={capabilitiesTotalsArray}
                view={radioSelect.current}
              />
              {/* THIS IS A NEW COMPONENT */}
              {/* 
              <CapacityReportTable
                countriesArray={countriesArray}
                grossTotal={grossTotal}
                viewTypes={viewTypes}
              /> */}
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row className="mt-5">
            <Col>
              <CapacityReportMap />
            </Col>
          </Row>
        </Container>
      </div>
      {/* Print Instructions Modal */}
      <Modal
        show={showPrintModal}
        onHide={handleCloseModal}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        id="print-pdf-settings-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Report as PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col
                xs={12}
                md={6}
              >
                <h6>For best results, please use these settings when saving the report</h6>
                <ol>
                  <li>Destination : Save as PDF</li>
                  <li>Pages: All</li>
                  <li>Layout: Landscape</li>
                </ol>
                <p>Select the "More Options" dropdown</p>
                <ol>
                  <li>Paper Size : A4</li>
                  <li>Pages per sheet: 1</li>
                  <li>Margins: Default</li>
                  <li>Scale: Select custom and scale down to 80%</li>
                  <li>Options: Enable Headers and Footers</li>
                  <li>Options: Enable Background Graphics</li>
                </ol>
              </Col>
              <Col
                xs={6}
                md={6}
              >
                <img
                  src={PrintPreview}
                  alt="print pdf settings"
                />
              </Col>
            </Row>
          </Container>

          <InputGroup className="mb-3">
            {/* <Form.Control placeholder="Share URL" aria-label="Share URL" value='' aria-describedby="share-btn" /> */}
            <Button
              variant="outline-primary"
              id="share-btn"
              onClick={handlePrint}
            >
              Print Report
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
