import { Row, Form, Col } from "react-bootstrap";
import CountriesList from "../countries/CountriesList";
// import ProgrammesList from "../programmes/ProgrammesList";
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import "./ReportFilterForm.scss";
import FilterDropdownList from "../dropdowns/FilterDropdownList";
import { useState } from "react";
import { useEffect } from "react";
import ProcessQueryParms from "../../utils/ProcessQueryParams";
import CreateQueryParams from "../../utils/CreateQueryParams";

// Let's get the query parameters if they exist
// const hasQueryParams = ProcessQueryParms();

function ReportFilterForm({ fetchCountryHandler, countriesArray, countriesArrayList, capabilitiesArray, sectorsArray, gendersArray, hasQueryParams = ProcessQueryParms(), rawData }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [FilterItems, setFilterItems] = useState([]);
  const [FilterTypes, setFilterTypes] = useState([]);
  const [countriesChecked, setCountriesChecked] = useState([]);

  let continents = ["Asia", "Africa", "North America", "South America", "Europe", "Oceania", "Unknown Continent"];

  // console.log(countriesArray);
  // Get the totals so we can grey out the countries with 0 graduates
  const totals = [];
  countriesArray.forEach((country) => {
    totals.push(country.overallTotal);
  });

  ///////////////////////
  // This is just a personal helper to count the totals of the countries for the map colors
  ///////////////////////

  // function splitCountriesByPopulation(countriesArray) {
  //   const overallTotalBelow100 = [];
  //   const overallTotal100to200 = [];
  //   const overallTotal200to300 = [];
  //   const overallTotal300to400 = [];
  //   const overallTotalAbove400 = [];

  //   countriesArray.forEach((country) => {
  //     if (country.overallTotal < 100) {
  //       overallTotalBelow100.push(country);
  //     } else if (country.overallTotal >= 100 && country.overallTotal < 200) {
  //       overallTotal100to200.push(country);
  //     } else if (country.overallTotal >= 200 && country.overallTotal < 300) {
  //       overallTotal200to300.push(country);
  //     } else if (country.overallTotal >= 300 && country.overallTotal < 400) {
  //       overallTotal300to400.push(country);
  //     } else {
  //       overallTotalAbove400.push(country);
  //     }
  //   });

  //   // console.log("0-100");
  //   // console.log(overallTotalBelow100);
  //   // console.log("100-200");
  //   // console.log(overallTotal100to200);
  //   // console.log("200-300");
  //   // console.log(overallTotal200to300);
  //   // console.log("300-400");
  //   // console.log(overallTotal300to400);
  //   console.log("400+");
  //   console.log(overallTotalAbove400);
  //   return [overallTotal100to200, overallTotal200to300, overallTotal300to400, overallTotalAbove400];
  // }

  // console.log(splitCountriesByPopulation(countriesArray));

  const handleCheckboxChange = (event) => {
    // console.log(event.target);
    const selectedItem = event.target.id;
    const isSelected = event.target.checked;

    const parentElement = event.target.closest(".dropdown-menu"); // Replace 'parent-class' with the actual class of the parent element
    // const directParent = event.target.closest(".form-check").className;
    const selectedType = parentElement ? parentElement.id : null;

    // Get all the checkboxes inside the dropdown menu
    const checkboxes = document.querySelectorAll("#" + selectedType + ' input[type="checkbox"]');

    // Loop through all the checkboxes and count the ones that are checked
    let checkedCount = 0;
    const continentsChecked = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount++;
        if (continents.includes(checkbox.id)) {
          continentsChecked.push(checkbox.id);
        }
      }
    });

    // console.log(`Number of checked checkboxes in #${selectedType}: ${checkedCount}`);
    // console.log(continentsChecked);

    // This is to reset the types when using the continent filter Selection.
    // START HERE if anything goes wrong with the filter
    // if (selectedType === "countries" && continentsChecked.length === 0) {
    //   // console.log(selectedItem);
    //   // console.log("Here it is!");
    //   if (continents.includes(selectedItem) && continentsChecked.length === 0) {
    //     setFilterTypes(FilterTypes.filter((type) => type !== selectedType));
    //   }
    // }
    // if (selectedType === "countries" && continentsChecked.length !== 0) {
    //   const selectedCountries = []; // Array to store filtered countries
      
    //   continentsChecked.forEach((continent) => {
    //     const countriesInContinent = countriesArray.filter((country) => {
    //       return country.continent === continent;
    //     });
    
    //     selectedCountries.push(...countriesInContinent.map(country => country.name));
    //   });
    
    //   setCountriesChecked([...countriesChecked, ...selectedCountries]);
    
    //   // Call CreateQueryParams for each selected country
    //   selectedCountries.forEach((country) => {
    //     // CreateQueryParams(selectedType, country);
    //   });
    // }    

    // console.log(countriesChecked);

    if (checkedCount !== 0) {
      // console.log("Check count is more than 0");
      if (selectedType && !FilterTypes.includes(selectedType)) {
        // console.log("has selectedType b ut filterTypes doesn't include it");
        setFilterTypes([...FilterTypes, selectedType]);
      }
    } else {
      // console.log("Check count is 0");
      if (selectedType && FilterTypes.includes(selectedType)) {
        setFilterTypes(FilterTypes.filter((type) => type !== selectedType));
      }
    }

    if (isSelected) {
      setSelectedItems([...selectedItems, selectedItem]);
      setFilterItems([...FilterItems, selectedItem]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== selectedItem));
      setFilterItems(FilterItems.filter((item) => item !== selectedItem));
    }

    // console.log(continentsChecked.length);

    CreateQueryParams(selectedType, selectedItem, rawData);
  };

  // console.log(selectedItems);
  // console.log(FilterItems);

  // Sort the countries alphabetically by name in their continents
  countriesArrayList.sort((a, b) => a.name.localeCompare(b.name));

  // Create an object to group countries by continent
  const groupedCountries = {};
  countriesArrayList.forEach((country) => {
    const continent = country.continent;
    if (!groupedCountries[continent]) {
      groupedCountries[continent] = [];
    }
    groupedCountries[continent].push(country);
  });

  // Extract the sorted countries from each continent group
  const sortedCountries = [];
  for (const continent in groupedCountries) {
    if (groupedCountries.hasOwnProperty(continent)) {
      sortedCountries.push(...groupedCountries[continent]);
    }
  }

  let countriesByContinenttoArray = [];

  /**
   * @function countriesByContinent
   * @param {array} continents - An array of continents
   * @param {array} countriesArrayList - An array of countries
   * @returns {array} countriesByContinenttoArray - An array of countries by continent
   *
   * This function maps through the continents array and pushes the continent name to the countriesByContinenttoArray.
   * It then maps through the countriesArrayList and pushes the country name to the countriesByContinenttoArray if the country's
   * continent matches the current continent in the loop.
   * Finally, the countriesByContinenttoArray is returned.
   */
  // eslint-disable-next-line no-unused-vars
  let indexCounter = 0;
  // eslint-disable-next-line no-unused-vars
  const countriesByContinent = continents.map((continent) => {
    countriesByContinenttoArray.push(continent);
    countriesArrayList.map((country) => {
      if (country.continent === continent) {
        countriesByContinenttoArray.push(country.name);
        indexCounter++;
      }
      return countriesByContinenttoArray.push();
    });
    return countriesByContinenttoArray.push();
  });

  // console.log(FilterItems);
  // console.log(FilterTypes);

  useEffect(() => {
    if (hasQueryParams[1]) {
      setSelectedItems(hasQueryParams[1]);
    }
    // console.log(FilterItems, FilterTypes);
    fetchCountryHandler(FilterItems, FilterTypes);
    // I'm disabling this warning because the fix causes an endless loop.
    // Preferred way to fix this is to destructure the props array before calling it in the useEffect function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FilterItems]);

  return (
    <Form className="search-form">
      <Form.Group
        as={Row}
        className="mb-3"
        controlId="formFiltersDropdown"
        onChange={handleCheckboxChange}
      >
        <Col
          md={6}
          lg={3}
          className="dropdown-list-column"
        >
          <FilterDropdownList
            dropdownType={"countries"}
            countriesArray={countriesByContinenttoArray}
            continents={continents}
            totals={totals}
          />
          <CountriesList countriesArray={countriesArray} />
          {selectedItems.length === 0 ? (
            <p>
              <b>Note: </b>
              {countriesArray.length - 8} other countries selected. Start filtering to refine.
            </p>
          ) : null}
        </Col>
        <Col
          md={6}
          lg={3}
          className="dropdown-list-column"
        >
          <FilterDropdownList
            dropdownType={"capabilities"}
            countriesArray={[]}
            continents={[]}
            capabilitiesArray={capabilitiesArray}
          />
          <div className="list-container mt-3">
            <ul className={FilterTypes.includes("capabilities") ? "list filtered" : "list"}>
              <li className={FilterItems.includes("Digital Financial Services") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#206CAF" }}
                ></div>
                Digital Financial Services
              </li>
              <li className={FilterItems.includes("Financial Regulation and Supervision") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#3A79B1" }}
                ></div>
                Digital Financial Inclusion Supervision
              </li>
              <li className={FilterItems.includes("Gender Equality") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#81B1DB" }}
                ></div>
                Gender Equality Changemaker
              </li>
              <li className={FilterItems.includes("Instant and Inclusive Payment Systems") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#589DD9" }}
                ></div>
                Instant and inclusive Payment Systems
              </li>
            </ul>
          </div>
        </Col>
        <Col
          md={6}
          lg={3}
          className="dropdown-list-column"
        >
          <FilterDropdownList
            dropdownType={"sectors"}
            countriesArray={[]}
            continents={[]}
            sectorsArray={sectorsArray}
          />
          <div className="list-container mt-3">
            <ul className={FilterTypes.includes("sectors") ? "list filtered" : "list"}>
              <li className={FilterItems.includes("Private") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#ACE575" }}
                ></div>
                Private
              </li>
              <li className={FilterItems.includes("Public") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#87C24E" }}
                ></div>
                Public
              </li>
              <li className={FilterItems.includes("Development") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#668449" }}
                ></div>
                Development
              </li>
              <li className={FilterItems.includes("Unknown") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#68A031" }}
                ></div>
                Unspecified
              </li>
            </ul>
          </div>
        </Col>
        <Col
          md={6}
          lg={3}
          className="dropdown-list-column"
        >
          <FilterDropdownList
            dropdownType={"gender"}
            countriesArray={[]}
            continents={[]}
            gendersArray={gendersArray}
          />
          <div className="list-container mt-3">
            <ul className={FilterTypes.includes("gender") ? "list filtered" : "list"}>
              <li className={FilterItems.includes("Male") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#F6B63C" }}
                ></div>
                Male
              </li>
              <li className={FilterItems.includes("Female") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#FAC96F" }}
                ></div>
                Female
              </li>
              <li className={FilterItems.includes("Other") ? "active" : "inactive"}>
                <div
                  className="index-color"
                  style={{ backgroundColor: "#FAE56F" }}
                ></div>
                Non-Binary and Undisclosed
              </li>
            </ul>
            <p>
              <b>Note: </b> we started collecting non-binary data from 2022.
            </p>
          </div>
        </Col>
      </Form.Group>
    </Form>
  );
}

export default ReportFilterForm;
