import "./CapacityReportMap.scss";

import WorldMap from "./world.svg";

function CapacityReportMap() {
  return (
    // <img src={WorldMap} alt="" width="100%" height="auto"/>
    <>
      <h2>Geographic Distribution of Participants</h2>
      <div className="capacity-report-map text-center">
        <img src="https://dfi-capacity.pages.dev/static/media/world.6d7d944902aadea331486f312decc065.svg" alt="" width="100%" height="auto" />

        <div className="country-legend text-start">
          <p>Number of participants</p>
          <div className="key-wrapper">
            <div className="key below-100"></div>
            <p>&lt;100</p>
          </div>
          <div className="key-wrapper">
            <div className="key below-200"></div>
            <p>100&lt;200</p>
          </div>
          <div className="key-wrapper">
            <div className="key below-300"></div>
            <p>200&lt;300</p>
          </div>
          <div className="key-wrapper">
            <div className="key below-400"></div>
            <p>300&lt;400</p>
          </div>
          <div className="key-wrapper">
            <div className="key over-400"></div>
            <p>&gt;400</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CapacityReportMap;
