import { Table } from "react-bootstrap";
// import getTotal from "../../utils/Utilities";

import "./CapacityReportTable.scss";
import { useEffect, useState } from "react";

function CapacityReportTableV2({ viewTypes, gendersArray, sectorsArray, capabilitiesArray, rawData, countriesArray, grossTotal, globalTotal, capabilitiesTotals, view }) {
  const [columnSpan, setColumnSpan] = useState("");

  // Set the views based on the filter if we're filtering
  if (viewTypes.length > 0) {
    if (viewTypes.includes("gender")) {
      gendersArray = gendersArray.filter((gender) => viewTypes.includes(gender));
    }
    if (viewTypes.includes("sectors")) {
      sectorsArray = sectorsArray.filter((sector) => viewTypes.includes(sector));
      rawData = rawData.filter((raw) => viewTypes.includes(raw.Sector));
    }
    if (viewTypes.includes("capabilities")) {
      capabilitiesArray = capabilitiesArray.filter((capability) => viewTypes.includes(capability));
    }
  }

  function calculateSectorPercentage(country, sector, newSectorsTotal) {
    let countrySector = null;
    let sectorTotal = null;

    if (country) {
      countrySector = countriesArray.find((obj) => obj.name === country)?.sectors;
    }
    if (countrySector) {
      sectorTotal = countrySector.find((obj) => obj.sector === sector);
    }

    return sectorTotal ? ((sectorTotal.total / newSectorsTotal) * 100).toFixed(2) + "%" : "0.00%";
  }

  let newSectorsTotal = countriesArray.reduce((total, country) => {
    const countrySector = country.sectors;
    if (countrySector) {
      return total + countrySector.reduce((sectorTotal, sector) => sectorTotal + sector.total, 0);
    }
    return total;
  }, 0);

  function calculateGenderPercentage(incomingGenderTotalsPerc, capability) {
    const matchingProgram = capabilitiesTotals.find((obj) => obj.program === capability);
    let totalGenderPerc = 0;

    incomingGenderTotalsPerc.forEach((innerArray) => {
      innerArray.forEach((value) => {
        totalGenderPerc += value;
      });
    });

    // console.log(matchingProgram.total);

    return ((totalGenderPerc / matchingProgram.total) * 100).toFixed(2) + "%";
  }

  function calculateGenderTotals(incomingGenderTotals) {
    let totalGender = 0;
    incomingGenderTotals.forEach((innerArray) => {
      innerArray.forEach((value) => {
        totalGender += value;
      });
    });
    return totalGender;
  }

  function calculateProgramPercentage(incomingProgramTotalsPerc) {
    let totalProgramPerc = 0;
    incomingProgramTotalsPerc.forEach((innerArray) => {
      innerArray.forEach((value) => {
        totalProgramPerc += value;
      });
    });
    return ((totalProgramPerc / globalTotal) * 100).toFixed(2) + "%";
  }

  function countThis(incomingTotal) {
    // console.log(incomingTotal);
    let thisTotal = 0;
    incomingTotal.forEach((value) => {
      thisTotal += value;
    });
    return thisTotal;
  }

  useEffect(() => {
    setColumnSpan(gendersArray.length);
  }, [gendersArray.length]);

  return (
    <>
      <h2 className="mb-4">Capacity Report Table</h2>
      <div className="capacity-report-table-wrapper">
        <Table
          responsive
          className="table capacity-report-table"
        >
          <thead>
            <tr className="table-programmes-row">
              <th className="table-header-col country">Country</th>
              {capabilitiesArray.map((capability) => (
                <th
                  key={capability}
                  className="text-center table-header-col"
                  colSpan={columnSpan}
                >
                  {capability}
                </th>
              ))}
              <th
                colSpan="1"
                className="text-center table-header-col attendees"
              >
                Participants / Graduates as percentage of your selection
              </th>
            </tr>
            <tr className="table-gender-row">
              <th className="border-right"></th>
              {capabilitiesArray.map((capability) =>
                gendersArray.map((gender) => (
                  <th
                    key={gender}
                    className="text-center"
                  >
                    <div className="full-height-col">
                      <span className="td-inner-wrapper">{gender === "Other" ? "Non-Binary and Undisclosed" : gender}</span>
                    </div>
                  </th>
                ))
              )}
              <th className="border-right"></th>
            </tr>
          </thead>
          {countriesArray.map((country) => (
            <tbody key={country.id}>
              <tr className="country-row">
                <td className="border-right">
                  <span className="td-inner-wrapper">{country.name}</span>
                </td>
                {capabilitiesArray.map((capability) =>
                  gendersArray.map((gender, index) => {
                    const modifiedGender = gender === "Other" ? "Unknown" : gender;
                    return (
                      <td
                        key={country.name + gender}
                        className={index === gendersArray.length - 1 ? "text-center border-right" : "text-center"}
                      >
                        <span className="td-inner-wrapper-totals">
                          {countThis(
                            rawData
                              .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                              .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates)).length === 0
                              ? [0]
                              : rawData
                                  .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                                  .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates))
                          )}
                        </span>
                      </td>
                    );
                  })
                )}
                <td className="text-center">
                  <span className="td-inner-wrapper-totals">{country.overallPercentage.toFixed(2)}%</span>
                </td>
              </tr>
              {sectorsArray.map((sector) => (
                <tr
                  key={sector + country.name}
                  className="sector-row"
                >
                  <td className="border-right">
                    <span className="td-inner-wrapper"> - {sector}</span>
                  </td>
                  {capabilitiesArray.map((capability) =>
                    gendersArray.map((gender, index) => {
                      const modifiedGender = gender === "Other" ? "Unknown" : gender;
                      return (
                        <td
                          key={sector + country.name + gender}
                          className={index === gendersArray.length - 1 ? "text-center border-right" : "text-center"}
                        >
                          <span className="td-inner-wrapper-totals">
                            {countThis(
                              rawData
                                .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Sector === sector && obj.Country === country.name)
                                .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates)).length === 0
                                ? [0]
                                : rawData
                                    .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Sector === sector && obj.Country === country.name)
                                    .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates))
                            )}
                          </span>
                        </td>
                      );
                    })
                  )}
                  <td className="text-center border-right">
                    <span className="td-inner-wrapper-totals">{calculateSectorPercentage(country.name, sector, newSectorsTotal)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          ))}

          <tfoot>
            <tr className="gender-totals-row">
              <td className="row-title">
                <span className="td-inner-wrapper">Participants by Gender</span>
              </td>

              {capabilitiesArray.map((capability) =>
                gendersArray.map((gender, index) => (
                  <td
                    key={capability + gender}
                    className={index === gendersArray.length - 1 ? "text-center border-right" : "text-center"}
                  >
                    <div className="full-height-col">
                      <span className="td-inner-wrapper-totals">
                        {calculateGenderTotals(
                          countriesArray.map((country) => {
                            const modifiedGender = gender === "Other" ? "Unknown" : gender;
                            return rawData
                              .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                              .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates)).length === 0
                              ? [0]
                              : rawData
                                  .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                                  .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates));
                          })
                        )}
                      </span>
                    </div>
                  </td>
                ))
              )}
            </tr>

            <tr className="gender-percentage-row">
              <td className="row-title">
                <span className="td-inner-wrapper">Participants by Gender %</span>
              </td>
              {capabilitiesArray.map((capability) =>
                gendersArray.map((gender, index) => (
                  <td
                    key={capability + gender}
                    className={index === gendersArray.length - 1 ? "text-center border-right" : "text-center"}
                  >
                    <div className="full-height-col">
                      <span className="td-inner-wrapper-totals">
                        {calculateGenderPercentage(
                          countriesArray.map((country) => {
                            const modifiedGender = gender === "Other" ? "Unknown" : gender;
                            return rawData
                              .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                              .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates)).length === 0
                              ? [0]
                              : rawData
                                  .filter((obj) => obj.Program === capability && obj.Gender === modifiedGender && obj.Country === country.name)
                                  .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates));
                          }),
                          capability // Pass this se we know what to match the totals with
                        )}
                      </span>
                    </div>
                  </td>
                ))
              )}
            </tr>

            <tr className="course-percentage-row">
              <td className="row-title">
                <span className="td-inner-wrapper-last">Programme % for your selection</span>
              </td>
              {capabilitiesArray.map((capability) => (
                <td
                  key={capability + "programtotal"}
                  className="text-center border-right"
                  colSpan={columnSpan}
                >
                  <div className="full-height-col">
                    <span className="td-inner-wrapper-last-totals">
                      {calculateProgramPercentage(
                        countriesArray.map((country) =>
                          rawData
                            .filter((obj) => obj.Program === capability && obj.Country === country.name)
                            .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates)).length === 0
                            ? [0]
                            : rawData
                                .filter((obj) => obj.Program === capability && obj.Country === country.name)
                                .map((obj) => (view === "participants" ? obj.Total_participants : obj.Total_graduates))
                        )
                      )}
                    </span>
                  </div>
                </td>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
}

export default CapacityReportTableV2;
